/* You can add global styles to this file, and also import other style files */
/* @import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'ngx-toastr/toastr-bs5-alert'; */



.custom-file-input1 {
  position: relative;
  overflow: hidden;
  display: inline-block;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B1B1B1;
  border-radius: 4px;
  padding: 5px;
}

.custom-file-input1 label {
  /* background-color: #3498db; */
  background-color: #f0f0f0;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #d9d9d9;
  color: #000;
  padding: 4px 13px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 4px;
}

.custom-file-input1 input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.custom-file-input1 span {
  margin-left: 10px; /* Adjust spacing between label and filename */
}

.custom-file-inpu1t input[type="file"]:not(:placeholder-shown) + span::before {
  content: 'File: ';
}

.custom-label
{
  position: absolute;
  top: -11px;
  font-size: 12px;
  color: #292d32c7;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: normal;
  transition: top 0.2s;
  color: #898383;
  left: 5px;
  padding-left: 15px;
  z-index: 1;
}


.master-data-label
{
  position: absolute;
  top: -11px;
  font-size: 12px;
  color: #292d32c7;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: normal;
  transition: top 0.2s;
  color: #999;
  left: 5px;
  z-index: 1;
}


:host ::ng-deep .ck-content {
    background-color: black !important;
    color: white !important;
  }

  :host ::ng-deep .ck-editor__editable {
    background-color: black;
    color: white;
  }

  :host ::ng-deep .ck-editor__editable_inline {
    min-height: 500px;
}

:host ::ng-deep .ck.ck-editor__main>.ck-editor__editable {
    background: #292d32 !important;
    border-radius: 0;
}

* {
  scrollbar-color: #333 #333;
}

.sidenavwrap::-webkit-scrollbar-thumb {
  background-color: #333 !important;
  outline: 1px solid #333 !important;
  border-radius: 10px;
}

.toggle-password{
  cursor: pointer;
}

.sidenavwrap .submenu1sub1 ul li a:hover {
  background-color: #000;
  color: #fff;
}